import React from 'react';

const Work = () => {
  return (
    <section id="work" className="py-12 bg-background text-bone-white md:pl-8 pl-2">
      <div className="container mx-auto px-4 lg:px-8 text-left">
        <h2 className="text-4xl font-bold mb-6">Work Experience</h2>
        <div className="mb-6">
          <h3 className="text-xl font-semibold">Kaiser Permanente - Software and IT Intern</h3>
          <div className="text-sm text-gray-400 mb-2">June 2024 - September 2024</div>
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <div className="text-description max-w-2xl mb-2">
           Revamped the user interface of internal tools and software while managing server data transfer operations.
          </div>
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold">Kaiser Permanente - Information Systems Intern</h3>
          <div className="text-sm text-gray-400 mb-2">June 2023 - September 2023</div>
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <div className="text-description max-w-2xl mb-2">
          Translated business goals into technical solutions for front-end internal applications, streamlining key workflows.
          </div>
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold">Aflac - Sales Intern</h3>
          <div className="text-sm text-gray-400 mb-2">January 2023 - June 2023</div>
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <div className="text-description max-w-2xl mb-2">
          Conducted needs assessments for clients and analyzed data to optimize outreach strategies, driving sustained growth.
          </div>
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold">YAPA KIDS - Curriculum Leader</h3>
          <div className="text-sm text-gray-400 mb-2">June 2020 - January 2022</div>
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <div className="text-description max-w-2xl mb-2">
          Led a team to develop lesson plans, designed engaging classes, and supported tutors to ensure equitable access to learning.
          </div>
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
      </div>
    </section>
  );
};

export default Work;
